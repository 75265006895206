export const menuItems = [
  {
    id: 1,
    label: "menu.menuitems.organization.text",
    icon: "bx-user",
    isAdmin: true,
    subItems: [
      {
        label: "menu.menuitems.organization.subItems.users",
        link: "/users",
        parentId: 1,
        auth_users: ["crm"],
      },
      {
        label: "menu.menuitems.organization.subItems.traiff",
        link: "/tariff",
        parentId: 1,
        auth_users: ["crm"],
      },
      {
        label: "menu.menuitems.organization.subItems.branch_sites",
        link: "/branch-sites",
        parentId: 1,
        auth_users: ["crm"],
      },
      // {
      //   label: "menu.menuitems.organization.subItems.employ-sector",
      //   link: "/emp_sectors",
      //   parentId: 1,
      // },
      // {
      //   label: "menu.menuitems.organization.subItems.monthly-salary-details",
      //   link: "/monthly-salary-details",
      //   parentId: 1,
      // },
      // {
      //   label: "menu.menuitems.organization.subItems.salary-item-degrees",
      //   link: "/salary_item_degrees",
      //   parentId: 1,
      // },
      // {
      //   label: "menu.menuitems.organization.subItems.salaryitemsbranches",
      //   link: "/salary_items_branches",
      //   parentId: 1,
      // },
    ],
  },
  // {
  //   id: 2,
  //   label: "menu.menuitems.reports.text",
  //   icon: "dripicons-document",
  //   isAdmin: true,
  //   can_see: ["admin"],
  //   subItems: [],
  // },
  // {
  //   id: 3,
  //   label: "menu.menuitems.employee-management.text",
  //   icon: "bx bx-file",
  //   isAdmin: true,
  //   can_see: ["admin"],
  //   subItems: [
  //     {
  //       label: "menu.menuitems.employee-management.subItems.emp-files",
  //       link: "/emp_files",
  //       parentId: 3,
  //     },
  //     {
  //       label: "menu.menuitems.employee-management.subItems.emp-file-types",
  //       link: "/emp_file_types",
  //       parentId: 3,
  //     },
  //   ],
  // },
  {
    id: 2,
    label: "menu.menuitems.crm.text",
    icon: "bx bx-fridge",
    isAdmin: true,
    subItems: [
      {
        label: "menu.menuitems.crm.subItems.tickets",
        link: "/tickets",
        parentId: 2,
        auth_users: ["crm"],
      },
      {
        label: "menu.menuitems.crm.subItems.tickets-report",
        link: "/tickets_report",
        parentId: 2,
        auth_users: ["crm"],
      },
    ],
  },
  // {
  //   id: 1,
  //   label: "menu.menuitems.customers.text",
  //   icon: " bx bxs-user ",
  //   can_see: ["admin"],
  //   subItems: [
  //     {
  //       label: "menu.menuitems.customers.text",
  //       link: "/customers",
  //       parentId: 1,
  //     },
  //     {
  //       label: "menu.menuitems.reports.text",
  //       link: "/customers_reports",
  //       parentId: 1,
  //     },
  //   ],
  // },
  // {
  //   id: 1,
  //   label: "menu.menuitems.purchases.text",
  //   icon: " bx bxs-user ",
  //   link: "/purchases",
  //   can_see: ["admin"],
  // },
  {
    id: 3,
    icon: " bx bxs-user ",
    label: "menu.menuitems.payments.text",
    subItems: [
      {
        label: "menu.menuitems.payments.sub.suppliers",
        link: "/suppliers",
        parentId: 3,
        auth_users: ["pm"],
      },
      {
        label: "menu.menuitems.payments.sub.invoices",
        link: "/payments_invoices",
        parentId: 3,
        auth_users: ["pm"],
      },
      {
        label: "menu.menuitems.payments.sub.payment_made",
        link: "/payment_made",
        parentId: 3,
        auth_users: ["cash"],
      },
      {
        label: "menu.menuitems.payments.sub.suppliers_report",
        link: "/suppliers_report",
        parentId: 3,
        auth_users: ["pm"],
      },
      {
        label: "popups.accounts_setup",
        link: "/payments_setup",
        parentId: 3,
        auth_users: ["am"],
      },
    ],
  },
  {
    id: 4,
    label: "menu.menuitems.hr.text",
    icon: "bx bxs-dollar-circle ",
    subItems: [
      {
        label: "menu.menuitems.hr.subItems.emps",
        link: "/emps",
        parentId: 4,
        auth_users: ["hm"],
      },
      {
        label: "menu.menuitems.hr.subItems.masters",
        link: "/masters",
        parentId: 4,
        auth_users: ["hm"],
      },
      {
        label: "menu.menuitems.salary.subItems.salary-items",
        link: "/salary-items",
        parentId: 4,
        auth_users: ["hm"],
      },
      {
        label: "menu.menuitems.salary.subItems.salary-deduction",
        link: "/salary-deduction",
        parentId: 4,
        auth_users: ["hm"],
      },
      // {
      //   label: "menu.menuitems.salary.subItems.salary-deduction",
      //   link: "/salary-deduction",
      //   parentId: 4,
      // },
      {
        label: "menu.menuitems.hr.subItems.emps-attendes",
        link: "/emps_attendes",
        parentId: 4,
        auth_users: ["hm"],
      },
      {
        label: "menu.menuitems.hr.subItems.emps-work-day",
        link: "/emp_work_day",
        parentId: 4,
        auth_users: ["hm"],
      },
      {
        label: "menu.menuitems.payroll.text",
        link: "/payroll",
        parentId: 4,
        auth_users: ["hm", "am"],
      },
      {
        label: "menu.menuitems.reports.text",
        link: "/emps_reports",
        parentId: 4,
        auth_users: ["hm"],
      },
      {
        label: "emps.setup",
        link: "/emps_setup",
        parentId: 4,
        auth_users: ["am"],
      },
      // {
      //   label: "menu.menuitems.payroll.subItems.payrollreport",
      //   link: "/payroll_report",
      //   parentId: 6,
      // },
      // {
      //   label: "menu.menuitems.hr.subItems.emps-report",
      //   link: "/emps_report",
      //   parentId: 6,
      // },
    ],
  },
  {
    id: 5,
    label: "menu.menuitems.reports.text",
    icon: " bx bx-list-check ",
    link: "/reports",
  },
  // {
  //   id: 7,
  //   label: "menu.menuitems.tree-of-accounts.text",
  //   icon: "bx bx-fridge",
  //   isAdmin: true,
  //   can_see: ["admin"],
  //   subItems: [
  //     {
  //       label:
  //         "menu.menuitems.tree-of-accounts.subItems.level_one_chart_of_accounts",
  //       link: "/level_one_chart_of_accounts",
  //       parentId: 7,
  //     },
  //     {
  //       label:
  //         "menu.menuitems.tree-of-accounts.subItems.level_two_chart_of_accounts",
  //       link: "/level_two_chart_of_accounts",
  //       parentId: 7,
  //     },
  //     {
  //       label:
  //         "menu.menuitems.tree-of-accounts.subItems.level_three_chart_of_accounts",
  //       link: "/level_three_chart_of_accounts_copy-1",
  //       parentId: 7,
  //     },
  //   ],
  // },

  {
    id: 6,
    label: "menu.menuitems.billing.text",
    icon: "bx bx-fridge",
    isAdmin: true,
    subItems: [
      {
        label: "menu.menuitems.customers.text",
        link: "/customers",
        parentId: 6,
        auth_users: ["crm"],
      },
      {
        label: "menu.menuitems.billing.subItems.billing_invoices",
        link: "/billing_invoices",
        parentId: 6,
        auth_users: ["cash", "crm"],
      },
      {
        label: "menu.menuitems.reports.text",
        link: "/customers_report",
        parentId: 6,
        auth_users: ["am"],
      },
      {
        label: "popups.accounts_setup",
        link: "/customers_setup",
        parentId: 6,
        auth_users: ["am"],
      },
      // {
      //   label: "menu.menuitems.billing.subItems.billing_report",
      //   link: "/billing_report",
      //   parentId: 6,
      // },
    ],
  },
  // {
  //   id: 8,
  //   label: "menu.menuitems.payroll.text",
  //   icon: "bx bx-fridge",
  //   isAdmin: true,
  //   can_see: ["admin"],
  //   subItems: [
  //     // {
  //     //   label: "menu.menuitems.payroll.subItems.payrollmanagment",
  //     //   link: "/payroll_managment",
  //     //   parentId: 8,
  //     // },
  //     {
  //       label: "menu.menuitems.payroll.subItems.monthemployeesalary",
  //       link: "/monthly_employee_salary",
  //       parentId: 8,
  //     },
  //   ],
  // },
  {
    id: 7,
    label: "menu.menuitems.accounting.subItems.fixed-assets",
    icon: "bx bx-fridge",
    subItems: [
      {
        label: "menu.menuitems.accounting.subItems.fixed-assets",
        link: "/fixed-assets",
        parentId: 7,
        auth_users: ["am"],
      },
      {
        label: "fixed_assets.disposed",
        link: "/disposed-assets",
        parentId: 7,
        auth_users: ["am"],
      },
      {
        label: "fixed_assets.categories",
        link: "/assets-categories",
        parentId: 7,
        auth_users: ["am"],
      },
      {
        label: "fixed_assets.classes",
        link: "/assets-classes",
        parentId: 7,
        auth_users: ["am"],
      },
      {
        label: "fixed_assets.reports",
        link: "/fixed-assets-reports",
        parentId: 7,
        auth_users: ["am"],
      },
      {
        label: "popups.accounts_setup",
        link: "/fixed-assets-setup",
        parentId: 7,
        auth_users: ["am"],
      },
    ],
  },
  {
    id: 8,
    label: "menu.menuitems.accounting.text",
    icon: "bx bx-fridge",
    isAdmin: true,
    subItems: [
      // {
      //   label: "menu.menuitems.accounting.subItems.accounting-transaction",
      //   link: "/accounting_transaction",
      //   parentId: 9,
      // },
      // {
      //   label: "menu.menuitems.accounting.subItems.manger-transaction",
      //   link: "/transaction_manger",
      //   parentId: 9,
      // },
      // {
      //   label:"menu.menuitems.accounting.subItems.general-ledger",
      //   link:"/general_ledger",
      //   parentId: 9
      // },
      {
        label: "menu.menuitems.accounting.subItems.charts-of-accounts",
        link: "/tree",
        parentId: 8,
        auth_users: ["am"],
      },
      {
        label: "menu.menuitems.accounting.subItems.journal_entries",
        link: "/journal_entries",
        parentId: 8,
        auth_users: ["am"],
      },
      {
        label: "menu.menuitems.accounting.subItems.cash-banks",
        link: "/cash_and_banks",
        parentId: 8,
        auth_users: ["am"],
      },
      // {
      //   label: "menu.menuitems.accounting.subItems.accounts",
      //   link: "/accounts",
      //   parentId: 8,
      // },
      // {
      //   label: "menu.menuitems.accounting.subItems.cheques",
      //   link: "/cheques",
      //   parentId: 8,
      // },
      // {
      //   label:"menu.menuitems.accounting.subItems.income-statement",
      //   link:"/income_statement",
      //   parentId:9
      // },
      // {
      //   label: "menu.menuitems.accounting.subItems.financial-statments",
      //   link: "/financial_statments",
      //   parentId: 8,
      // },
      // {
      //   label:"menu.menuitems.accounting.subItems.cashflow-statement",
      //   link:"/cashflow_statement",
      //   parentId: 8
      // },
      // {
      //   label: "menu.menuitems.accounting.subItems.revenues",
      //   link: "/revenues",
      //   parentId: 8,
      // },
      // {
      //   label: "menu.menuitems.accounting.subItems.trial-balance",
      //   link: "/trial_balance",
      //   parentId: 8,
      // },
      // {
      //   label: "menu.menuitems.accounting.subItems.opening-balances",
      //   link: "/opening_balances",
      //   parentId: 8,
      // },
      {
        label: "menu.menuitems.accounting.subItems.accounting-periods",
        link: "/accounting-peroids",
        parentId: 8,
        auth_users: ["am"],
      },
      // {
      //   label: "menu.menuitems.accounting.subItems.income-expenditure",
      //   link: "/income_expenditure",
      //   parentId: 8,
      // },
      // {
      //   label: "menu.menuitems.accounting.subItems.general-ledger",
      //   link: "/general_ledger",
      //   parentId: 8,
      // },
      // {
      //   label:"menu.menuitems.accounting.subItems.asset-valuation",
      //   link:"/asset_valuation",
      //   parentId: 8
      // }
      // {
      //   label: "menu.menuitems.accounting.subItems.debtors",
      //   link: "/debtors",
      //   parentId: 8,
      // },
      // {
      //   label: "menu.menuitems.accounting.subItems.creditors",
      //   link: "/creditors",
      //   parentId: 8,
      // },
      // {
      //   label: "menu.menuitems.accounting.subItems.bill-age-analysis-report",
      //   link: "/bill_age_analysis_report",
      //   parentId: 8,
      // },
      // {
      //   label: "menu.menuitems.accounting.subItems.bills-payable",
      //   link: "/bills_payable",
      //   parentId: 8,
      // },
      // {
      //   label: "menu.menuitems.accounting.subItems.bills-receivable",
      //   link: "/bills_receivable",
      //   parentId: 8,
      // },
      // {
      //   label: "menu.menuitems.accounting.subItems.balance-sheet",
      //   link: "/balance_sheet",
      //   parentId: 8,
      // },
      {
        label: "menu.menuitems.accounting.subItems.budget",
        link: "/budget",
        parentId: 8,
        auth_users: ["am"],
      },
      // {
      //   label: "menu.menuitems.accounting.subItems.setup",
      //   link: "/accounts_setup",
      //   parentId: 8,
      // },
      // {
      //   label: "menu.menuitems.accounting.subItems.budget-vs-actual-report",
      //   link: "/budget_vs_actual_report",
      //   parentId: 8,
      // },
      // {
      //   label: "menu.menuitems.accounting.subItems.asset-valuation",
      //   link: "/asset_valuation",
      //   parentId: 8,
      // },
      // {
      //   label: "menu.menuitems.accounting.subItems.assets-depreciation",
      //   link: "/assets_depreciation",
      //   parentId: 8,
      // },
    ],
  },
];
